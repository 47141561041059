import clsx from "clsx"
import Head from "next/head"
import * as React from "react"
import { Anchor, UploadCareImage } from "~/core/components"
import { useInterval, useToggle } from "~/core/hooks"
import { ChampagneCheers, KeyLock, TravelSuitcase } from "~/marketing/components/vector-art"
import * as Routes from "~/routes"

// UploadCare UUIDs
const benefitsLeftImageId = "6705daec-ca2e-4ca5-9700-becaa7cf8950"
const benefitsRightImageId = "b994d937-48e6-4bc6-bc18-6d19fe1cca10"
const memberProductImageId = "64f08cd5-842b-493d-b97a-992ece555afc"
const ownerProductImageId = "797de543-05ad-4408-b9be-bc395fce5eb4"

const heroImages = [
  "3989d78b-7830-4c03-9267-8a0186fd3e4e",
  "64c9f471-1b26-4702-8457-5dc2cd6a52c1",
  "bb3450cc-ccf9-4eb7-ab48-0b3a74e14f00",
  "79249819-01b3-4c1d-860f-35afcfa3983a",
  "ca7d144b-a4b9-4e4d-b4fa-9075fe2a01f6",
  // "dd2a12c1-f175-41b2-95ec-2c3ef9b1e6f3", // GlenEagles is not licensed
]

const Page = () => (
  <>
    <Head>
      <title>Sonato - Hospitality Simplified</title>
      <meta
        name="description"
        content="Sonato Alliance is the invitation-only network of the world's best independent private social clubs."
      />
    </Head>
    <HeroSection />
    <PerksSection />
    <ProductsSection />
  </>
)

function Carousel({ images, rotateInterval = 4500, crossfadeDuration = 2000 }) {
  const [focusFirst, toggleFocus] = useToggle(true)
  const [current, setCurrent] = React.useState({ a: 0, b: 1 })

  useInterval(toggleFocus, rotateInterval)
  React.useEffect(() => {
    const timer = setTimeout(
      () => setCurrent(({ a, b }) => (focusFirst ? { a, b: a + 1 } : { a: b + 1, b })),
      crossfadeDuration + 100
    )
    return () => clearTimeout(timer)
  }, [crossfadeDuration, focusFirst])

  return (
    <>
      <div
        className={clsx(
          "absolute h-full w-full overflow-hidden transition-opacity",
          focusFirst ? "opacity-100" : "opacity-0"
        )}
        style={{ transitionDuration: `${crossfadeDuration}ms` }}
      >
        <UploadCareImage
          uuid={images[current.a % images.length]}
          width="1600"
          height="900"
          priorty={true}
          coverPosition="bottom"
          alt=""
        />
      </div>
      <div
        className={clsx(
          "absolute h-full w-full overflow-hidden transition-opacity",
          !focusFirst ? "opacity-100" : "opacity-0"
        )}
        style={{ transitionDuration: `${crossfadeDuration}ms` }}
      >
        <UploadCareImage
          uuid={images[current.b % images.length]}
          width="1600"
          height="900"
          coverPosition="bottom"
          alt=""
        />
      </div>
    </>
  )
}

const HeroSection = () => (
  <section>
    <div className="max-w-7xl text-center sm:text-left">
      <h1 className="mx-12 mt-12 font-serif text-3xl font-semibold sm:mx-24 sm:mt-24 sm:text-4xl md:mx-28 md:mt-28 md:text-[80px] md:leading-[80px] xl:mx-36 xl:mt-36">
        We make meaningful connections between members & clubs
      </h1>
      <p className="mx-auto mt-5 max-w-xs sm:mx-24 sm:mt-12 sm:max-w-full sm:text-md md:mx-28 xl:mx-36">
        Sonato Alliance is the invitation-only network of the world's best independent private
        social clubs. Sonato Alliance gives members seamless access to partner clubs as distinctive
        as their own when traveling the globe.
      </p>
    </div>
    <div className="relative mt-12 h-[300px] sm:mt-16 sm:block sm:h-[500px] md:mt-20 md:ml-28 md:h-[640px] lg:h-[720px] xl:ml-36 xl:mt-24 xl:h-[800px]">
      <Carousel images={heroImages} />
    </div>
  </section>
)

const PerksSection = () => (
  <section className="mt-12 space-y-12 md:mt-24 md:space-y-24">
    <div className="mx-auto grid max-w-7xl space-y-12 sm:grid-cols-3 sm:space-y-0 sm:px-8">
      <div className="px-12 text-center sm:px-8">
        <KeyLock className="mx-auto h-16 w-16 text-gold-900 sm:h-16 sm:w-16" />
        <h3 className="mt-5 font-serif text-2xl font-semibold md:text-3xl">
          Exclusive club access
        </h3>
        <p className="mt-2 sm:mt-4 md:text-md">
          Members have exclusive access to their club's bespoke network of partners.
        </p>
      </div>
      <div className="px-12 text-center sm:px-8">
        <ChampagneCheers className="mx-auto h-16 w-16 text-gold-900 sm:h-16 sm:w-16" />
        <h3 className="mt-5 font-serif text-2xl font-semibold md:text-3xl">
          Unforgettable experiences
        </h3>
        <p className="mt-2 sm:mt-4 md:text-md">
          Each independent club offers members authentic, local events and programming.
        </p>
      </div>
      <div className="px-12 text-center sm:px-8">
        <TravelSuitcase className="mx-auto h-16 w-16 text-gold-900 sm:h-16 sm:w-16" />
        <h3 className="mt-5 font-serif text-2xl font-semibold md:text-3xl">Travel the globe</h3>
        <p className="mt-2 sm:mt-4 md:text-md">
          Members always have a home away from home with a growing network of clubs worldwide.
        </p>
      </div>
    </div>
    <div className="relative flex justify-between space-x-1">
      <div className="w-full sm:w-1/2">
        <UploadCareImage
          uuid={benefitsLeftImageId}
          width="400"
          height="300"
          alt="bar area"
          sizes="(min-width:724px) 50vw,100vw"
        />
      </div>
      <div className="hidden sm:block sm:w-1/2">
        <UploadCareImage
          uuid={benefitsRightImageId}
          width="400"
          height="300"
          alt="entrance"
          sizes="50vw"
        />
      </div>
    </div>
  </section>
)

const ProductsSection = () => (
  <section className="mt-12 mb-16 px-12 sm:mx-auto sm:mt-16 sm:max-w-5xl sm:px-16">
    <div className="text-center">
      <h2 className="font-serif text-3xl font-semibold md:text-4xl">Our products</h2>
      <p className="mt-2 font-sans text-xs font-bold uppercase md:text-sm">
        Hospitality Simplified
      </p>
    </div>
    <div className="grid sm:grid-cols-2 sm:gap-16">
      <section>
        <div className="mt-10">
          <Anchor href={Routes.about()} className="block border-[10px] border-gold-100 shadow-lg">
            <UploadCareImage
              uuid={memberProductImageId}
              width="400"
              height="500"
              alt="member app"
              sizes="(min-width: 724px) 50vw,100vw"
            />
          </Anchor>
        </div>
        <div className="mt-12 text-center">
          <h3 className="font-serif text-2xl font-semibold md:text-3xl">Club Members</h3>
          <p className="mt-2 sm:mt-4 md:text-md">
            Unlock access to private clubs worldwide, booking visits and communicating with partner
            clubs on the Sonato Alliance mobile app.
          </p>
        </div>
        <div className="mt-8 flex justify-center">
          <Anchor
            href={Routes.about()}
            className="block rounded-full border border-gold-900 px-5 py-2 font-sans text-sm font-bold uppercase text-gold-900 transition duration-300 hover:bg-gold-900 hover:text-white"
          >
            What we build
          </Anchor>
        </div>
      </section>
      <section>
        <div className="mt-10">
          <Anchor href={Routes.about()} className="block border-[10px] border-gold-100 shadow-lg">
            <UploadCareImage
              uuid={ownerProductImageId}
              width="400"
              height="500"
              alt="admin app"
              sizes="(min-width: 724px) 50vw,100vw"
            />
          </Anchor>
        </div>
        <div className="mt-8 text-center md:mt-12">
          <h3 className="font-serif text-2xl font-semibold md:text-3xl">Owners & Operators</h3>
          <p className="mt-2 sm:mt-4 md:text-md">
            The invitation-only Sonato Alliance brings an elegant upgrade to the traditional
            reciprocal network, streamlining formation of club partnerships and management of member
            visits.
          </p>
        </div>
        <div className="mt-8 flex justify-center">
          <Anchor
            href={Routes.about()}
            className="block rounded-full border border-gold-900 px-5 py-2 font-sans text-sm font-bold uppercase text-gold-900 transition duration-300 hover:bg-gold-900 hover:text-white"
          >
            What we build
          </Anchor>
        </div>
      </section>
    </div>
  </section>
)

export async function getStaticProps() {
  return {
    props: {
      layout: "marketing",
    },
    // revalidate IMPORTANT: otherwise Next.js sends: cache-control: s-maxage=31536000, stale-while-revalidate
    revalidate: 30,
  }
}

export default Page
